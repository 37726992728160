import React from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { rollup_t, tasklist_t, task_t, team_t, type_t } from './types'
import { Chip, InputLabel, Link, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Grid from '@mui/material/Grid'
import { useQuery, gql } from "@apollo/client"
import { type } from '@testing-library/user-event/dist/type';


interface TaskModalProps {
	task: task_t
	handleClose: () => void
}

const DATA_QUERY = gql`
  {
		teams {
			code
			long_name
		 }
		 rollups {
			rollup_id
			name
			description
			tasks {
				task_id
				name
			 }
		 }
		 types {
			type
		 }
	}
`

const getRollupTasks = (currentTask: task_t, rollups: rollup_t[]): tasklist_t => {
	const tasks: tasklist_t = []

	rollups.map((rollup: rollup_t) => {
		if (rollup.rollup_id == currentTask.rollup_id) {
			rollup.tasks!.map((t: task_t) => {
				if (t.task_id != currentTask.task_id) {
					console.log(t.name)
					tasks.push(t)
				}
			})
		}
	})

	return tasks
}

export default function TaskDialog({ taskRef, handleClose }: { taskRef: task_t, handleClose: () => void }) {
	const [open, setOpen] = React.useState(true);
	const [task, setTask] = React.useState(taskRef);
	const { data, loading, error } = useQuery(DATA_QUERY);

	const handleCloseLocal = () => {
		setOpen(false)
		handleClose()
	};

	const handleSave = () => {
		taskRef = task
		setOpen(false)
		handleClose()
	}

	const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTask({ ...task, name: event.target.value })
	}

	const handleDescChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTask({ ...task, description: event.target.value })
	}

	const handlePriChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTask({ ...task, priority: parseInt(event.target.value) })
	}

	const handleHCChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTask({ ...task, hc: parseInt(event.target.value) })
	}

	const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTask({ ...task, type: event.target.value })
	}

	const handleTeamChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTask({ ...task, team_code: event.target.value })
	}

	const handleCatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTask({ ...task, rollup_id: parseInt(event.target.value) })
	}


	console.log(data)
	let relatedTasks: tasklist_t = []
	if (!loading && !error)
		relatedTasks = getRollupTasks(task, data.rollups)

	return (
		<div>
			<Dialog open={open} fullWidth maxWidth='md' onClose={handleCloseLocal}>
				<DialogTitle>Plan Line Item</DialogTitle>
				<DialogContent>
					<Grid container item spacing={2}>
						<Grid item xs={12}>
							<TextField autoFocus id="name" label="Name" size='small' fullWidth variant="filled" value={task.name} onChange={handleNameChange} />
						</Grid>
						<Grid item xs={12}>
							<TextField id="description" multiline rows={4} size='small' label="Description" fullWidth variant="filled" value={task.description} onChange={handleDescChange} />
						</Grid>
						<Grid item xs={1}>
							<TextField id="priority" label="Pri" size='small' type="number" variant="filled" value={task.priority} onChange={handlePriChange} />
						</Grid>
						<Grid item xs={1}>
							<TextField id="hc" label="HC" size='small' type="number" variant="filled" value={task.hc} onChange={handleHCChange} />
						</Grid>
						<Grid item xs={3}>
							<TextField id="type-select" select={true} variant="filled" fullWidth size='small' value={task.type}
								label="Type" onChange={handleTypeChange}>
								{!loading && !error && data.types.map((type: type_t, index: number) => (
									<MenuItem key={index} value={type.type}>{type.type}</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid item xs={3}>
							<TextField id="team-select" select={true} variant="filled" fullWidth size='small' value={task.team_code}
								label="Team" onChange={handleTeamChange}>
								{!loading && !error && data.teams.map((team: team_t, index: number) => (
									<MenuItem key={index} value={team.code}>{team.long_name}</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid item xs={4}>
							<TextField id="category-select" select={true} variant="filled" fullWidth size='small' value={task.rollup_id.toString()}
								label="Category" onChange={handleCatChange}>
								{!loading && !error && data.rollups.map((rollup: rollup_t, index: number) => (
									<MenuItem key={index} value={rollup.rollup_id.toString()}>{rollup.name}</MenuItem>
								))}
							</TextField>
						</Grid>
						{!loading && !error && <Grid item xs={12}>
							<p>Related Tasks</p>

							{relatedTasks.map(t => (
								<Chip label={t.name} style={{margin: '1px'}} color="primary" size="small" onClick={() => {}} />
							))}
						</Grid>}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button color='primary' variant='contained' onClick={handleSave}>Save</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}