import React from 'react'
import Task from './task'
import { task_t, tasklist_t } from './types'
import './App.css'
import { useQuery, gql } from "@apollo/client"
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import TaskDialog from './task.modal'

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'

const TASKS_QUERY = gql`
  {
    tasks {
      task_id
      name
		description
		team_code
		hc
		priority
		type
		rollup_id
		important
		rollup {
			name
		}
		team {
			long_name
		}
	 }
  }
`

type groupedTasks_t = {
	groupName: string
	tasks: tasklist_t
	open: boolean
}

function App() {
	const [dialogTask, setDialogTask] = React.useState<task_t | null>(null)
	const [tasks, setTasks] = React.useState<tasklist_t>([])
	const [groupedTasks, setGroupedTasks] = React.useState<groupedTasks_t[]>([])
	const [groupBy, setGroupBy] = React.useState("none")
	const [sortBy, setSortBy] = React.useState("id")
	const [logged, setLogged] = React.useState(false)
	const { data, loading, error } = useQuery(TASKS_QUERY);
	const [, updateState] = React.useState({});
	const forceUpdate = React.useCallback(() => updateState({}), []);

	if (!error && !loading && !logged) {
		setLogged(true)
		let cumHC = 0, order = 0
		const newTasks: tasklist_t = []
		data.tasks.forEach((task: task_t) => {
			cumHC = cumHC + task.hc
			order = order + 1

			const newTask = { ...task, chc: cumHC, order: order }
			newTasks.push(newTask)
		})
		setTasks(newTasks)
	}

	const buildTaskList = (groupBy: string) => {
		const groupedTasks: groupedTasks_t[] = []

		switch (groupBy) {
			case 'epic':
				{
					const epics: string[] = []

					tasks.forEach((task: task_t) => {
						if (!epics.includes(task.rollup.name))
							epics.push(task.rollup.name)
					})

					console.log("epics:", epics)

					epics.forEach(epic => {
						const group: groupedTasks_t = { groupName: epic, open: true, tasks: [] }
						tasks.forEach((task: task_t) => {
							if (epic == task.rollup.name)
								group.tasks.push(task)
						})
						groupedTasks.push(group)
					})

					break;
				}

			case 'team':
				{
					const teams: string[] = []

					tasks.forEach((task: task_t) => {
						if (!teams.includes(task.team.long_name))
							teams.push(task.team.long_name)
					})

					console.log("teams:", teams)

					teams.forEach(team => {
						const group: groupedTasks_t = { groupName: team, open: true, tasks: [] }
						tasks.forEach((task: task_t) => {
							if (team == task.team.long_name)
								group.tasks.push(task)
						})
						groupedTasks.push(group)
					})

					break;
				}

			case 'type':
				{
					const types: string[] = []

					tasks.forEach((task: task_t) => {
						if (!types.includes(task.type))
							types.push(task.type)
					})

					console.log("types:", types)

					types.forEach(type => {
						const group: groupedTasks_t = { groupName: type, open: true, tasks: [] }
						tasks.forEach((task: task_t) => {
							if (type == task.type)
								group.tasks.push(task)
						})
						groupedTasks.push(group)
					})

					break;
				}
		}
		console.dir(groupedTasks)
		setGroupedTasks(groupedTasks)
	}

	const toggleGroupOpen = (group: groupedTasks_t) => {
		group.open = !group.open;
		//console.log("group", group.groupName, "is now", group.open ? "open" : "closed");
		forceUpdate()
	}

	const handleClose = () => {
		setDialogTask(null)
	};

	const setGroup = (newGroupBy: string) => {
		console.log("Group by", groupBy, "->", newGroupBy)
		setGroupBy(newGroupBy)
		buildTaskList(newGroupBy)
	}

	const setSort = (newSortBy: string) => {
		console.log("Sort by", sortBy, "->", newSortBy)
		setSortBy(newSortBy)
	}

	return (
		<div className="App">
			<header className="App-header">
				{error && <pre>{error.message}</pre>}
				{loading && <pre>Loading...</pre>}
				<h4>Shield Q4 Priorities</h4>
			</header>

			{dialogTask && <TaskDialog taskRef={dialogTask!} handleClose={handleClose} />}

			{!error && !loading &&
				<div style={{ margin: '24pt 64pt', padding: '24pt 64pt', border: 'black 2px solid', borderRadius: '8px' }}>
					<Grid container spacing={0}>
						<Grid container item xs={3}>
							<Grid container>
								<Grid item xs={3}><div className="label">Group By</div></Grid>
								<Grid container item xs={9}>
									<ToggleButtonGroup value='none' color='primary' sx={{ boxShadow: '0 0 4px 1px rgba(200, 200, 220, 50)', height: '24px'}} 
										exclusive onChange={(event: React.MouseEvent<HTMLElement>, value: string | null) => setGroup(value || 'none')}>
										<ToggleButton value="none" size="small" selected={groupBy === 'none'}>None</ToggleButton>
										<ToggleButton value="epic" size="small" selected={groupBy === 'epic'}>Epic</ToggleButton>
										<ToggleButton value="team" size="small" selected={groupBy === 'team'}>Team</ToggleButton>
										<ToggleButton value="type" size="small" selected={groupBy === 'type'}>Type</ToggleButton>
									</ToggleButtonGroup>
								</Grid>
							</Grid>
						</Grid>
						<Grid container item xs={3}>
							<Grid container>
								<Grid item xs={3}><div className="label">Sort By</div></Grid>
								<Grid item xs={9}>
									<ToggleButtonGroup value='id' color='primary' exclusive sx={{ border: '0px rgb(80, 100, 140) solid', boxShadow: '0 0 4px 1px rgba(200, 200, 220, 50)', height: '24px'}} 
										onChange={(event: React.MouseEvent<HTMLElement>, value: string | null) => setSort(value || 'none')}>
										<ToggleButton value="id" size="small" selected={sortBy === 'id'}>ID</ToggleButton>
										<ToggleButton value="priority" size="small" selected={sortBy === 'priority'}>Priority</ToggleButton>
										<ToggleButton value="team" size="small" selected={sortBy === 'team'}>Team</ToggleButton>
										<ToggleButton value="type" size="small" selected={sortBy === 'type'}>Type</ToggleButton>
									</ToggleButtonGroup>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<br />
					<Grid container>
						<Task.Header groupBy={groupBy} />
						{groupBy === 'none' && <>
							{tasks.map((task: task_t) =>
								<Task.Row key={task.task_id.toString()} task={task} setTaskDialog={setDialogTask} cumHC={task.chc} order={task.order} />
							)}
						</>
						}
						{groupBy !== 'none' &&
							groupedTasks.map((group: groupedTasks_t, index: number) => (<>
								<Task.GroupHeader key={group.groupName} group_type={groupBy} group_name={group.groupName}
									groupIx={index} toggleOpen={() => toggleGroupOpen(group)} />

								{!!group.open && <>
									{group.tasks.map((task: task_t) =>
										<Task.Row key={task.task_id.toString()} task={task} setTaskDialog={setDialogTask}
											groupBy={groupBy} groupIx={index} cumHC={task.chc} order={task.order} />
									)}
								</>
								}
							</>)
							)
						}
					</Grid>
					<br />
					<br />
					<Grid container justifyContent='flex-end'>
						<Button variant='contained'>Add</Button>
						<Button disabled>Delete</Button>
					</Grid>
				</div>
			}

		</div >
	);
}

export default App;
