import React from 'react'
import { task_t } from './types'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'

interface TaskProps {
	task: task_t
	cumHC: number
	order: number
	setTaskDialog: (task: task_t) => void
	groupBy?: string
	groupIx?: number
}

interface HeaderProps {
	groupBy?: string
}

interface GroupHeaderProps {
	group_type: string
	group_name?: string
	groupIx?: number
	toggleOpen?: () => void
}

const groupColors = ['#ffffff', '#1a237e', '#4a148c', '#b71c1c', '#01579b', '#004d40', '#827717', '#ff6f00', '#37474f']

const Header: React.FC<HeaderProps> = ({ groupBy }) => {
	const _groupBy = groupBy || ""
	return (<Grid className="task-header" container item columnSpacing={4}>
		<Grid xs={1} item>ID</Grid>
		{_groupBy !== 'epic' && <Grid xs={2} item>Epic</Grid>}
		<Grid xs={2} item>Name</Grid>
		{_groupBy !== 'team' && <Grid xs={1} item>Team</Grid>}
		{/*<Grid xs={1} item>Priority</Grid>*/}
		{_groupBy !== 'type' && <Grid xs={1} item>Type</Grid>}
		<Grid xs={3} item>Description</Grid>
		<Grid xs={1} item>HC</Grid>
		<Grid xs={1} item>CHC</Grid>
	</Grid>)
}

const GroupHeader: React.FC<GroupHeaderProps> = ({ group_type, group_name, groupIx, toggleOpen }) => {
	const colIx = groupIx === undefined ? 0 : groupIx % 8 + 1
	return (<Grid className="group-header" sx={{ backgroundColor: groupColors[colIx] }} container item columnSpacing={4} onClick={() => { if (toggleOpen) toggleOpen() }}>
		<Grid xs={12} item>{group_name}</Grid>
	</Grid>)
}

const Row: React.FC<TaskProps> = ({ task, cumHC, order, setTaskDialog, groupBy, groupIx }) => {
	const [lineDrawn, setLineDrawn] = React.useState(false)
	const _groupBy = groupBy || ""
	const colIx = groupIx === undefined ? 0 : groupIx % 8 + 1

	return (<>
		{!lineDrawn && task.priority == 100 && <Grid className="task-row cutoff-line" container item xs={12}>&nbsp;</Grid>}
		<Grid className="task-row" container item sx={{borderBottom: groupColors[colIx] + ' 1px solid'}}
			columnSpacing={4} xs={12} onClick={() => setTaskDialog(task)}>
			{!!colIx && <div style={{ width: '4px', backgroundColor: groupColors[colIx] }}>&nbsp;</div>}
			<Grid xs={1} item>{order}</Grid>
			{_groupBy !== 'epic' && <Grid xs={2} item>{task.rollup.name}</Grid>}
			<Grid xs={2} item>{task.name}</Grid>
			{_groupBy !== 'team' && <Grid xs={1} item>{task.team_code}</Grid>}
			{/*<Grid xs={1} item className="number">{task.priority}</Grid>*/}
			{_groupBy !== 'type' && <Grid xs={1} item>{task.type}</Grid>}
			<Grid xs={3} item>{task.description}</Grid>
			<Grid xs={1} item className="number">{task.hc}</Grid>
			<Grid xs={1} item className="number">{cumHC}</Grid>
		</Grid>
	</>)
}

export default { Row, Header, GroupHeader }
